import React from 'react'
import * as Styled from './styles/HomePageHero.styles'
import { HeroGraphicHomepage } from '../Icons'

const HomePageHero = ({ headerText, heroImage }) => (
  <Styled.HomePageHero>
    {headerText && <Styled.HeroText>{headerText}</Styled.HeroText>}
    <Styled.HeroGraphicContainer>
      <HeroGraphicHomepage />
    </Styled.HeroGraphicContainer>
    {heroImage?.url && (
      <Styled.HeroImage
        src={heroImage.url}
        alt={heroImage.alt ? heroImage.alt : 'hero image'}
      />
    )}
  </Styled.HomePageHero>
)

export default HomePageHero
