import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'

import SEO from '../components/SEO'
import HomepageHero from '../components/Heroes/HomePageHero'
import Layout from '../components/Layout'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'
import LinkListWithHeading from '../components/ContentBlocks/LinkListWithHeading'
import Interstitial from '../components/ContentBlocks/Interstitial'

const IndexPage = ({ data }) => {
  const doc = data?.prismicHomepage
  if (!doc) {
    return null
  }

  const twitterImage = doc.data.twitter_image?.url
    ? doc.data.twitter_image
    : doc.data.hero_image?.url
    ? doc.data.hero_image
    : null
  const facebookImage = doc.data.facebook_image?.url
    ? doc.data.facebook_image
    : doc.data.hero_image?.url
    ? doc.data.hero_image
    : null
  const seoTitle = doc.data.meta_title?.text
    ? doc.data.meta_title.text
    : 'National Psoriasis Foundation'
  const seo_embed_code = doc.data.seo_embed_code?.text ?? null

  return (
    <>
      {/* <pre>{JSON.stringify(doc, false, 2)}</pre> */}
      <SEO
        seo={{
          description: doc.data.meta_description,
          keywords: doc.data.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <HomepageHero
          headerText={doc.data.header_text?.text ?? ''}
          heroImage={doc.data.hero_image}
        />

        <LinkListWithHeading
          heading={doc.data.audience_journey_subtitle}
          copy={doc.data.audience_journey_body_text}
          links={doc.data.audience_journey_links}
          pullComponentUp
        />

        {doc.data.body &&
          doc.data.body.map(block => (
            <SliceRenderer block={block} key={block.id} />
          ))}
      </Layout>

      {!!doc.data.interstitial_text && (
        <Interstitial
          copy={doc.data.interstitial_text}
          ctaLink={doc.data.link}
          ctaText={doc.data.link_text}
          image={doc.data.image}
          expiry={doc.data.expiry_hours}
        />
      )}
    </>
  )
}

export const query = graphql`
  {
    prismicHomepage {
      type
      data {
        header_text {
          text
        }
        hero_image {
          alt
          url
          thumbnails {
            mobile {
              url
            }
          }
        }
        audience_journey_body_text {
          richText
          text
        }
        audience_journey_subtitle {
          richText
        }
        audience_journey_links {
          audience_journey_page_link_text
          audience_journey_page_link {
            url
            uid
            target
            type
            link_type
            id
          }
        }
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        interstitial_text
        expiry_hours
        link_text
        image {
          alt
          url
          thumbnails {
            mobile {
              url
            }
          }
        }
        link {
          url
          uid
          target
          type
          link_type
        }
        body {
          ... on PrismicHomepageDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyBasicImageBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              image_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyAdvancedImageBlock {
            id
            slice_type
            primary {
              layout_option
              text_content {
                richText
              }
              cta_text {
                text
              }
              cta_link {
                uid
                link_type
                id
                type
                url
              }
              header_text {
                text
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyGridBlock {
            id
            slice_type
            primary {
              layout_type
              bottom_padding_adjustment
            }
            items {
              cta_link_text
              cta_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              summary {
                richText
              }
              title {
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyCalendarOfEventsBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              layout_style
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyAccordionBlock {
            id
            slice_type
            primary {
              section_header {
                text
              }
              bottom_padding_adjustment
            }
            items {
              content {
                richText
              }
              block_header {
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyVideoBlock {
            id
            slice_type
            primary {
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_thumbnail {
                url
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyAuthorBlock {
            id
            slice_type
            primary {
              author {
                document {
                  ... on PrismicGuestAuthor {
                    id
                    type
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicNpfAuthor {
                    id
                    type
                    uid
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      bio {
                        richText
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyStatisticsBlock {
            id
            slice_type
            primary {
              citation
              statistic_prompt
              header {
                text
              }
              subhead {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyCustomForm {
            id
            slice_type
            primary {
              form_title
              form_embed_code {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyIconTextBlock {
            id
            primary {
              header {
                richText
              }
              body_text {
                richText
              }
              bottom_padding_adjustment
            }
            slice_type
            items {
              cta_text
              item_title {
                richText
              }
              item_icon {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              item_body_text {
                richText
              }
              cta_link {
                id
                url
                uid
                type
                link_type
              }
            }
          }
          ... on PrismicHomepageDataBodySponsorsBlock {
            id
            slice_type
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicHomepageDataBodyEventBlock {
            id
            slice_label
            primary {
              bottom_padding_adjustment
            }
            items {
              event {
                id
                document {
                  ... on PrismicEvent {
                    data {
                      end_date
                      event_date
                      event_city
                      event_state
                      end_time
                      start_time
                      event_venue
                      event_address_line_1
                      event_address_line_2
                      event_image {
                        alt
                        url
                        thumbnails {
                          mobile {
                            url
                          }
                        }
                      }
                      event_title {
                        text
                      }
                      event_description {
                        text
                      }
                      event_link {
                        id
                        link_type
                        uid
                        url
                        type
                      }
                      event_link_text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
