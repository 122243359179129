import React, { useState, useEffect } from 'react'

import {
  getStorageString,
  setStorageString,
  StorageKey,
} from '../../util/storage'
import useDeviceDetect from '../../util/useDeviceDetect'

import { linkResolver } from './../../util/linkResolver'
import { slugify } from '../../util/strings'

import * as Styled from './styles/Interstitial.styles'

const Interstitial = ({ copy, ctaText, ctaLink, expiry, image }) => {
  const { isMobile } = useDeviceDetect()

  const [isVisible, setIsVisible] = useState(false)
  const [hasClosed, setHasClosed] = useState(false)

  const interstialId = slugify(`${copy}-${ctaText}`)
  const previouslyClosedInterstitial = getStorageString({
    key: StorageKey.INTERSTITIAL_VIEWED,
  })
  const previouslyClosedInterstitialTime = getStorageString({
    key: StorageKey.INTERSTITIAL_VIEWED_TIME,
  })

  useEffect(() => {
    if (
      (!previouslyClosedInterstitial ||
        previouslyClosedInterstitial !== interstialId) &&
      !isVisible
    ) {
      setIsVisible(true)
    }
  }, [previouslyClosedInterstitial, interstialId, setIsVisible, isVisible])

  useEffect(() => {
    const now = Date.now()
    const hoursSinceClosed = (
      (now - previouslyClosedInterstitialTime) /
      (1000 * 60 * 60)
    ).toFixed(1)

    if (
      (hoursSinceClosed >= expiry || !previouslyClosedInterstitialTime) &&
      !isVisible &&
      !hasClosed
    ) {
      setIsVisible(true)
    }
  }, [previouslyClosedInterstitialTime, setIsVisible, isVisible])

  const handleClose = () => {
    setStorageString({
      key: StorageKey.INTERSTITIAL_VIEWED,
      value: interstialId,
    })

    setStorageString({
      key: StorageKey.INTERSTITIAL_VIEWED_TIME,
      value: Date.now(),
    })

    setIsVisible(false)
    setHasClosed(true)
  }

  if (!isVisible) {
    return null
  }

  return (
    <Styled.Container>
      <Styled.Inner>
        <Styled.ContentContainer>
          <Styled.CloseButton onClick={handleClose}>
            <Styled.CloseButtonIcon>
              <svg
                width="24"
                height="26"
                viewBox="0 0 24 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="1.5"
                  y1="-1.5"
                  x2="30.8567"
                  y2="-1.5"
                  transform="matrix(0.675152 -0.737679 0.675152 0.737679 2.1543 25.8689)"
                  stroke="#F2F6F8"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <line
                  x1="1.5"
                  y1="-1.5"
                  x2="30.8567"
                  y2="-1.5"
                  transform="matrix(0.675152 0.737679 -0.675152 0.737679 0 2.13114)"
                  stroke="#F2F6F8"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Styled.CloseButtonIcon>
            <Styled.CloseButtonLabel>Close</Styled.CloseButtonLabel>
          </Styled.CloseButton>
          {!!image && (
            <Styled.Picture>
              {image.thumbnails?.mobile?.url && (
                <source
                  media="(max-width: 499px)"
                  srcSet={image.thumbnails.mobile.url}
                />
              )}
              <source media="(min-width: 500px)" srcSet={image.url} />
              <img src={image.url} alt={image.alt} />
            </Styled.Picture>
          )}

          <Styled.Copy>{copy}</Styled.Copy>
          {!!ctaLink && !!ctaText && (
            <Styled.Cta
              href={
                ctaLink.link_type === 'Web' || ctaLink.link_type === 'File'
                  ? ctaLink.url
                  : linkResolver(ctaLink)
              }
              onClick={handleClose}
            >
              <Styled.CtaLabel>{ctaText}</Styled.CtaLabel>
            </Styled.Cta>
          )}
        </Styled.ContentContainer>
      </Styled.Inner>
      {!isMobile && <Styled.ClickTarget onClick={handleClose} />}
    </Styled.Container>
  )
}

export default Interstitial
