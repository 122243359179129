import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import { DEVICE } from '../../../util/constants'

export const Container = styled.div`
  background-color: var(--content-background-light);
  display: grid;
  grid-template-columns: 32px repeat(10, 1fr) 32px;
  grid-template-rows: max-content 27px auto;
  margin: ${props => (props.pullComponentUp ? '-125px 0 50px' : '0 0 50px')};
  padding: 150px 0 50px;

  @media ${DEVICE.tabletL} {
    grid-template-columns: 1fr repeat(10, 1fr) 1fr;
    margin: ${props => (props.pullComponentUp ? '-140px 0 80px' : '0 0 80px')};
    max-width: 1317px;
    padding: 160px 0 50px;
  }

  @media ${DEVICE.laptopS} {
    margin: ${props => (props.pullComponentUp ? '-250px 0 80px' : '0 0 80px')};
    padding: 70px 0;
  }
`

export const Heading = styled.div`
  color: var(--content-background-dark-blue);
  grid-column: 2 / 12;
  grid-row: 1;
  margin-bottom: 30px;

  @media ${DEVICE.laptopS} {
    grid-column: 2 / 6;
    margin-bottom: 40px;
  }

  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 2.8rem;
    font-weight: 100;
    line-height: 1.357;

    @media ${DEVICE.tabletL} {
      font-size: 3.6rem;
    }

    @media ${DEVICE.laptopS} {
      font-size: 4.8rem;
    }
  }
`

export const Copy = styled.div`
  color: var(--content-background-dark-blue);
  grid-column: 2 / 12;
  grid-row: 2;

  @media ${DEVICE.laptopS} {
    grid-column: 2 / 6;
  }

  > p {
    font-size: 1.4rem;
    line-height: 1.357;

    @media ${DEVICE.tabletL} {
      font-size: 1.6rem;
    }

    @media ${DEVICE.laptopS} {
      font-size: 2.4rem;
      grid-column: 2 / 6;
    }
  }
`

export const LinkContainer = styled.div`
  grid-column: 2 / 12;
  grid-row: 3;
  margin-top: 20px;

  @media ${DEVICE.tabletL} {
    margin-top: 40px;
  }

  @media ${DEVICE.laptopS} {
    margin-top: 40px;
  }
`

export const LinkEyebrow = styled.div`
  color: var(--content-background-dark-blue);
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.357;
  margin-bottom: 20px;
  text-transform: uppercase;

  @media ${DEVICE.tabletL} {
    font-size: 1.4rem;
  }
`

export const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0 0 -20px;

  @media ${DEVICE.tabletS} {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -15px -32px;
  }
`

export const LinkListItem = styled.li`
  margin-bottom: 20px;

  @media ${DEVICE.tabletS} {
    margin-bottom: 32px;
    padding: 0 15px;
    width: 50%;
  }

  @media ${DEVICE.tabletL} {
    width: 33.333%;
  }

  @media ${DEVICE.laptopS} {
    width: 25%;
  }
`

export const Link = styled(GatsbyLink)`
  color: var(--content-background-dark-blue);
  display: block;
  font-size: 1.8rem;
  line-height: 1.357;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  @media ${DEVICE.tabletL} {
    font-size: 2rem;
    position: relative;
    z-index: 0;

    &::after {
      background-color: var(--graphic-accent-purple-1);
      border-radius: 100%;
      content: '';
      display: block;
      height: 100px;
      left: 20px;
      opacity: 0;
      position: absolute;
      top: calc(50% - 50px);
      transition: opacity 150ms ease-in-out;
      width: 100px;
      z-index: -1;
    }

    :hover {
      &::after {
        opacity: 1;
      }
    }
  }

  @media ${DEVICE.laptopS} {
    font-size: 2.8rem;
  }
`
