import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { HeroH1 } from '../../styles/Utility.styles'

export const HomePageHero = styled.div`
  display: grid;
  grid-template-columns: 32px repeat(10, 1fr) 32px;
  grid-template-rows: max-content 27px auto;

  @media ${DEVICE.tabletL} {
    grid-template-columns: 1fr repeat(10, 1fr) 1fr;
    max-width: 1317px;
  }
`

export const HeroText = styled(HeroH1)`
  grid-column: 2 / 12;
  grid-row: 1;
  padding-bottom: 25px;
  padding-top: 24px;

  @media ${DEVICE.tabletL} {
    grid-column: 2 / 11;
  }

  @media ${DEVICE.laptopS} {
    grid-column: 2 / 11;
  }
`

export const HeroGraphicContainer = styled.div`
  grid-column: 2 / 10;
  grid-row: 3 / 4;

  @media ${DEVICE.mobileL} {
    grid-column: 6 / 10;
  }
`

export const HeroImage = styled.img`
  grid-column: 5 / 12;
  grid-row: 2 / 4;

  @media ${DEVICE.mobileL} {
    grid-column: 8 / 12;
  }
`
