import React from 'react'
import { PrismicRichText } from '@prismicio/react'

import { linkResolver } from './../../util/linkResolver'

import * as Styled from './styles/LinkListWithHeading.styles'

const LinkListWithHeading = ({ heading, copy, links, pullComponentUp }) => (
  <Styled.Container pullComponentUp={pullComponentUp}>
    <Styled.Heading>
      <PrismicRichText field={heading.richText} />
    </Styled.Heading>
    <Styled.Copy>
      <PrismicRichText field={copy.richText} />
    </Styled.Copy>

    <Styled.LinkContainer>
      <Styled.LinkEyebrow>Select One:</Styled.LinkEyebrow>

      <Styled.LinkList>
        {links.map(link => {
          const {
            audience_journey_page_link_text,
            audience_journey_page_link,
          } = link

          return (
            <Styled.LinkListItem key={audience_journey_page_link.id}>
              <Styled.Link to={linkResolver(audience_journey_page_link)}>
                {audience_journey_page_link_text}
              </Styled.Link>
            </Styled.LinkListItem>
          )
        })}
      </Styled.LinkList>
    </Styled.LinkContainer>
  </Styled.Container>
)

export default LinkListWithHeading
